import React from "react";
import MasterLayout from "../components/MasterLayout";
import Article from "../components/Article";
import Seo from "../components/Seo";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import "./index.css";

export default function Home({ data }) {
  const mostRecentArticle = data.allMdx.edges[0].node;

  return (    
    <MasterLayout>
      <Seo />
      <div className="main-content">
        <p>
          Welcome to Jared's Hot Takes
        </p>
        <p>
          This is fun site for me to record all of my 100% objective observations about games I've played. It probably won't always be limited to video games, I may occasionally review a movie or TV show I've seen recently.
        </p>
        <div className="most-recent">
          <p>
            <b>Most Recent Article</b>
          </p>
          <Article
            slug={`./reviews${mostRecentArticle.fields.slug}`}
            title={mostRecentArticle.frontmatter.title}
            date={mostRecentArticle.frontmatter.date}
            blurb={mostRecentArticle.frontmatter.blurb}
            excerpt={mostRecentArticle.excerpt}/>          
        </div>        
      </div>
    </MasterLayout>
  );
}

export const query = graphql`
  query {
    file(relativePath: {eq: "mass-effect-le.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    allMdx(sort: {fields: frontmatter___date, order:DESC}) {
      edges {
        node {
          id
          frontmatter {
              title
              date(formatString: "YYYY-MM-DD")
              blurb
          }
          fields {
            slug
          }
          excerpt
        }      
      }
    }
  }
`