import React from "react";
import { Link } from "gatsby";
import "./Article.css";

export default function Article(props) {
  return (
    <article>
      <div className="card-container">
        <Link to={`.${props.slug}`}>
          <h2>{props.title} - {props.blurb}</h2>
          <div className="separator"/>
          <div>
            First Published: {props.date}
          </div>
          <p>
            {props.excerpt}
          </p>
          <div>
            Read More →
          </div>
        </Link>
      </div>
    </article>
  );
}